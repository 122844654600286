@import '~antd/dist/antd.less';

.ant-rate {
  &.modalRate {
    font-size: 36px !important;
    .ant-rate-star {
      margin-right: 15px;
    }
  }
  font-size: 14px !important;
  color: #ffb547 !important;
}

.ant-btn {
  border-radius: 18px !important;
  min-width: 87px;
  font-weight: 500;
}

.ant-modal-header,
.ant-modal-footer {
  border: none;
}

.ant-modal {
  &.comment {
    .ant-modal-footer {
      margin-top: -35px;
    }
  }
  &.success {
    width: 360px !important;
    .ant-modal-content {
      border-radius: 8px;
    }
  }
}

.ant-modal-footer {
  padding-bottom: 24px;
}

.ant-modal-close-x {
  color: #737e8d;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.ant-input,
.ant-select-selector,
.ant-input-affix-wrapper {
  border-color: #00142a1f !important;
  border-radius: 4px !important;
}

.ant-upload.ant-upload-select-picture-card {
  background: white;
}

.ant-popover {
  max-width: 400px;
}

.projectDetailPopover {
  &.ant-popover {
    min-width: 400px;
    max-width: 1100px;
  }
}

.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 12px;
  &:hover {
    background-color: white !important;
  }
}

.ant-pagination-item-active {
  border-color: transparent;
}

.ant-btn-primary[disabled] {
  border: none;
}

textarea.ant-input {
  min-height: 123px;
}

.ant-popover-placement-top .ant-popover-arrow {
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 769px) {
    display: block;
  }
}

@primary-color: #18BB97;@link-color: #0fcd8c;@logo-color: #39E1A4;