/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'kccfont';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Regular'), url('SFPRODISPLAYREGULAR.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Ultralight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Ultralight Italic'), url('SFPRODISPLAYULTRALIGHTITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Thin Italic'), url('SFPRODISPLAYTHINITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Light Italic'), url('SFPRODISPLAYLIGHTITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Medium';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Medium'), url('SFPRODISPLAYMEDIUM.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Semibold Italic'), url('SFPRODISPLAYSEMIBOLDITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont-bold';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Bold'), url('SFPRODISPLAYBOLD.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Heavy Italic'), url('SFPRODISPLAYHEAVYITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Black Italic'), url('SFPRODISPLAYBLACKITALIC.woff') format('woff');
}

@font-face {
  font-family: 'kccfont Number Normal';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Number Normal'), url('URWDIN-Regular.otf') format('otf');
}

@font-face {
  font-family: 'kccfont Number Medium';
  font-style: normal;
  font-weight: normal;
  src: local('kccfont Number Medium'), url('URWDIN-Medium.otf') format('otf');
}

